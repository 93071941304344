<template>
    <ul class="flex">
        <!-- <Scroll ref="scroll" :on-reach-bottom="list.length >= length ? null :handleReachBottom" :height="height" :distance-to-edge="10" loading-text="正在加载数据，请稍等...">
        <Card dis-hover v-for="(item, index) in list" :key="index" style="margin: 32px 0">
            Content {{ item }}
        </Card>
        <div v-if="list.length >= length" style="color: #ccc;text-align: center;line-height: 50px">我也是有底线的</div>
    </Scroll>
    <Button @click="loadingMore">加载更多</Button> -->
    <!--试写一个老虎机样式组件-->
     <ScrollNum 
      v-for="(number, idx) of numArr"
      :key="idx"
      :i="number"
      :delay="idx + 2.5"
      as="li"
      class="num"></ScrollNum>
    </ul>
   
</template>

<script>
//@route('/testscroll')
   import ScrollNum from './children/ScrollNum'
   export default {
       components:{ScrollNum},
       data() {
            return {
              list: [1,2,3,4,5,6,7,8,9,10,11,12,13],
              height: '',
              length: 30,
              num: 222
           }
        },
        computed: {
            numArr () {
            const str = String(this.num)

            return [parseInt(str[0]), parseInt(str[1]), parseInt(str[2])]
            }
        },
        methods: {
            loadingMore() {
                this.$refs.scroll.showBottomLoader = true;
                this.$refs.scroll.onReachBottom();
            },
            handleReachBottom() {
                if(this.list.length >= this.length) {
                    return false;
                }
                return new Promise(resolve => {
                    console.log(this.$refs.scroll.showBottomLoader);
                    this.addList(resolve);
                })
            },
            addList(resolve) {
                let length = this.list.length;
                if(length > this.length) {
                     resolve();
                     return
                }
                setTimeout(() => {
                    for(let i = length + 1;i <= length + 10;i++) {
                    this.list.push(i);
                }
                 this.$refs.scroll.showBottomLoader = false;
                    resolve()
                },1000)
            }
        },
        mounted() {
            this.height = window.innerHeight - 40;
        }
      
    }
</script>
    
<style scoped lang='less'>
.flex {
  display: flex;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.num {
  --width: 26px;
  margin-right: 6px;
  border: 1px solid black;
  border-radius: 8px
}
</style>